import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CustomFieldType } from '@clover/custom-fields/models/custom-field';
import { DateService } from '@core/services/date.service';

import { type TreeviewPickerOption } from '../components/treeview-picker/treeview-picker.component';
import { type CompanyDataField, type CompanySearchModel } from '../models/company';
import { type User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  private readonly translate = inject(TranslateService);
  private readonly dateService = inject(DateService);

  public getCompanyDescription(company: CompanySearchModel): string {
    if (!company.description) {
      return this.translate.instant('advancedSearchPage.noCompanyDescription');
    }

    if (company.description.length <= 130) {
      return company.description;
    }

    return `${company.description.slice(0, 130)}...`;
  }

  public treeSearch(options: TreeviewPickerOption[], value: string): TreeviewPickerOption {
    let option;

    options.find((opt) => {
      if (opt.value === value) {
        option = opt;
        return true;
      }

      if (opt.suboptions && opt.suboptions.length > 0) {
        option = this.treeSearch(opt.suboptions, value);
        return !!option;
      }

      return false;
    });

    return option;
  }

  public parseTreeOptions(data: any[], childrenProperty?: string): TreeviewPickerOption[] {
    if (!data) {
      return [];
    }

    return data.map((filter) => {
      const formattedFilter: TreeviewPickerOption = {
        value: filter.key,
        label: filter.title,
      };

      if (childrenProperty && filter[childrenProperty] !== undefined && filter[childrenProperty].length > 0) {
        formattedFilter.suboptions = this.parseTreeOptions(filter[childrenProperty], childrenProperty);
      }

      return formattedFilter;
    });
  }

  public getProprietaryFieldValue(field: CompanyDataField): string {
    if (!field?.value) {
      return '';
    }

    if (field.type === CustomFieldType.Date) {
      return this.dateService.format(field.value, {
        date: 'numeric',
      });
    }

    if (field.type === CustomFieldType.Contact) {
      const value = this.getContactValue(field);
      return `${value?.firstName} ${value?.lastName}${value?.title ? ', ' + value.title : ''}`;
    }

    if (field.type === CustomFieldType.Location) {
      return `${field.value?.streetAddressLine1} ${field.value?.streetAddressLine2 || ''} ${field.value?.city},
       ${field.value?.state} ${field.value?.zipcode}`;
    }

    if (field.type === CustomFieldType.MultiOptionList && field.value?.keys?.join) {
      return field.value.keys.join(',');
    }

    return field.value?.displayValue || field.value?.title || field.value;
  }

  public getContactValue(field: CompanyDataField): User {
    if (typeof field.value === 'string') {
      return JSON.parse(field.value);
    }

    return field.value;
  }

  public getCookie(name): string {
    const matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
}
